import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)


const routes = [
  
  {
    path: '/',
    name: 'login',
    component: () => import( '../views/login.vue')
  },
  
  // {
  //   path: '/',
  //   name: 'Cadastro',
  //   component: () => import('../views/Cadastro.vue')
  // },


  {
    path: '/parcelas',
    name: 'Parcelas',
    component: () => import('../views/Parcelas.vue')
  },
  
  {
    path: '/cadastro',
    name: 'Cadastro',
    component: () => import('../views/Cadastro.vue')
  },

  {
    path: '/tabelas',
    name: 'tabelas',
    component: () => import('../views/Tabelas.vue')
  },

  {
    path: '/configuracoes',
    name: 'configuracoes',
    component: () => import('../views/Configuracoes.vue')
  },

  {
    path: '/dsi',
    name: 'dsi',
    component: () => import('../views/DSi.vue')
  },

  {
    path: '/print',
    name: 'Print',
    component: () => import( '../views/Print.vue')
  },

  {
    path: '/financeiro',
    name: 'Financeiro',
    component: () => import( '../views/Financeiro.vue')
  },

  {
    path: '/print_c',
    name: 'print_c',
    component: () => import( '../views/print_cadastro.vue')
  },

  {
    path: '/Lembretes',
    name: 'Lembretes',
    component: () => import(/* webpackChunkName: "about" */ '../views/Lembretes.vue')
  },

  {
    path: '/chat',
    name: 'chat',
    component: () => import(/* webpackChunkName: "about" */ '../views/chat.vue')
  },

  {
    path: '/editar',
    name: 'editar',
    component: () => import(/* webpackChunkName: "about" */ '../views/Editar.vue')
  },

  {
    path: '/home',
    name: 'home',
    component: () => import(/* webpackChunkName: "about" */ '../views/Home.vue')
  },

  {
    path: '/arte',
    name: 'arte',
    component: () => import(/* webpackChunkName: "about" */ '../views/Arte.vue')
  },

  {
    path: '/WhatsBot',
    name: 'WhatsBot',
    component: () => import(/* webpackChunkName: "about" */ '../views/WhatsBot.vue')
  },

  {
    path: '/dre',
    name: 'dre',
    component: () => import(/* webpackChunkName: "about" */ '../views/DRE.vue')
  },

  {
    path: '/relatorios',
    name: 'relatorios',
    component: () => import(/* webpackChunkName: "about" */ '../views/Relatorios.vue')
  },

  {
    path: '/agenda',
    name: 'agenda',
    component: () => import(/* webpackChunkName: "about" */ '../views/Agenda.vue')
  },


  {
    // path: '/12052009',
    path: '/12052009/:token',
    name: '12052009',
    component: () => import(/* webpackChunkName: "about" */ '../views/novo_cliente.vue')
  },


]

const router = new VueRouter({
  routes
})

export default router
